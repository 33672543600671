<template>
  <div class="delete-account">
    <div v-show="!showConfirmDelete">
      <div class="flex-start-between marginB35">
        <div>
          <h2 class="fs-24 marginB26">{{$t('deleteAccount')}}</h2>
          <p class="gray">{{$t('secureAccount')}}</p>
        </div>
      </div>
      <el-input
        tabindex='1'
        autocomplete
        v-model="account"
        @keyup.enter.native="verifyChanges"
        @input="changesInput"
        :placeholder="$t('accountPlaceholder')"></el-input>
      <div class="flex-center pass-box">
        <el-input
          :class="{ 'error-input': accountError }"
          tabindex='2'
          autocomplete
          type="password"
          v-model="password"
          show-password
          @keyup.enter.native="verifyChanges"
          @input="changesInput"
          :placeholder="$t('passPlaceholder')"></el-input>
        <el-button
          v-if="!canClickDelete && !accountError"
          v-repeat-click="{event:verifyChanges}"
          class="sign-submit-btn verify-btn"
          :class="{ 'gray-btn': !canClickVerify }"
          :loading="isLoading">{{$t('verify')}}</el-button>
        <div
          class="el-icon-error error-icon"
          v-if="accountError"></div>
        <div
          class="el-icon-check success-icon"
          v-if="!accountError && canClickDelete"></div>
      </div>
      <span
        class="error-text"
        v-if="accountError">Incorrect account or password.</span>
      <el-button
        @click="deleteAccount"
        class="sign-submit-btn delete-btn"
        :class="canClickDelete ? '' : 'gray-btn'"
        :loading="isDeleteLoading">{{$t('deleteButton')}}</el-button>
    </div>
    <div v-show="showConfirmDelete">
      <div v-if="!deleteSuccess">
        <div class="marginB26 flex-item-center">
          <i
            @click="back"
            class="el-icon-back back-btn fs-18 fw-700"></i>
          <h2 class="fs-24">{{$t('deleteAccount')}}</h2>
        </div>
        <div class="lineH24">{{$t('confirmDelText')}}</div>
        <div class="btn-box">
          <el-button
            @click="back"
            class="sign-submit-btn cancel-btn">{{$t('cancel')}}</el-button>
          <el-button
            @click="confirm"
            class="sign-submit-btn confirm-btn"
            :loading="isConfirmLoading">{{$t('delete')}}</el-button>
        </div>
      </div>
      <div
        v-else
        class="delete-success">
        <img
          src="../assets/images/success.png"
          width="80"
          alt="">
        <h2 class="fs-24 dark-black">{{$t('deleteSuccess')}}</h2>
        <div class="gray">{{$t('deleteSuccessTip')}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

const sha512 = require('js-sha512')
export default {
  name: 'DeleteAccount',
  data () {
    return {
      account: '',
      password: '',
      isLoading: false,
      isDeleteLoading: false,
      canClickVerify: false,
      canClickDelete: false,
      domainName: '',
      accountError: false,
      showConfirmDelete: false,
      deleteSuccess: false,
      isConfirmLoading: false,
      code: ''
    }
  },
  computed: {
    ...mapState({
      httpsFlag: 'httpsFlag',
      tagKeys: 'tagKeyList',
      publicServers: 'publicServers',
      isPc: 'isPc'
    })
  },
  created () {
    const localUrl = window.location.hostname
    const str1 = localUrl.split('.')[1]
    const str2 = localUrl.split('.')[2]
    this.domainName = `.${str1}.${str2}`
  },
  methods: {
    changesInput () {
      if (this.accountError) {
        this.accountError = false
      }
      if (this.canClickDelete) {
        this.canClickDelete = false
      }
      if (this.account && this.password) {
        this.canClickVerify = true
      } else {
        this.canClickVerify = false
      }
    },
    verifyChanges () {
      if (!this.canClickVerify) return
      const condition = {
        account: this.account.replace(/\s*/g, ''),
        loginType: 1,
        password: sha512(this.password).toUpperCase()
      }
      this.isLoading = true
      this.$axios.get(
        '/userGroup/user-group/user/verifyAccount', {
          params: condition
        }
      ).then((res) => {
        if (res.data.errorCode == '0x0') {
          this.code = res.data.result
          this.canClickDelete = true
        } else if (res.data.errorCode == '80951101') {
          // 账号或者密码错误
          this.accountError = true
        } else {
          this.$message({
            message: res.data.errorInfo,
            type: 'error',
            duration: 3000
          })
        }
        this.isLoading = false
      }).catch(() => {
        this.canClickDelete = false
        this.isLoading = false
      })
    },
    deleteAccount () {
      if (!this.canClickDelete) return
      this.showConfirmDelete = true
    },
    back () {
      this.showConfirmDelete = false
    },
    confirm () {
      this.isConfirmLoading = true
      this.$axios.get(
        '/userGroup/user-group/user/cancelAccount',
        {
          params: {
            account: this.account.replace(/\s*/g, ''),
            code: this.code
          }
        }
      ).then((res) => {
        if (res.data.errorCode == '0x0') {
          this.deleteSuccess = true
        } else {
          this.$message({
            message: res.data.errorInfo,
            type: 'error',
            duration: 3000
          })
        }
        this.isConfirmLoading = false
      }).catch(() => {
        this.isConfirmLoading = false
      })
    }
  }
}
</script>

<style scoped lang="less">
.pass-box {
  margin-top: 18px;
}
.verify-btn {
  width: 64px;
  height: 40px;
  line-height: 40px;
  margin-left: 16px;
}
.gray-btn {
  border-radius: 8px;
  background: var(--grey-5, #D9D9D9);
}
.success-icon {
  margin-left: 16px;
  font-size: 32px;
  color: #33AB4F;
  font-weight: 700;
}
.error-icon {
  margin-left: 16px;
  font-size: 28px;
  color: #E56464;
}
.error-input /deep/.el-input__inner {
  border-color: #E56464;
}
.error-text {
  margin-top: 4px;
  color: #E56464;
}
.delete-btn {
  margin-top: 131px;
}
.back-btn {
  margin: 3px 8px 0 -30px;
  cursor: pointer;
}
.btn-box {
  display: flex;
  margin-top: 268px;
  .cancel-btn {
    background-color: #8C8C8C;
  }
  .confirm-btn {
    background-color: #F6445A;
    margin-left: 24px;
  }
}
.delete-success {
  text-align: center;
  padding: 150px 0;
  h2 {
    margin-top: 15px;
    margin-bottom: 8px;
  }
}
</style>
